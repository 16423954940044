import { IColumns } from "./productDetailColumns";

export const Laser_Marking_Machine: IColumns[][] = [
  [
    {
      id: "partNumber",
      name: "Part Number",
      customeWidth: 100,
      colSpan: 1,
      align: "center",
    },
    {
      id: "material",
      name: "Material",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "color",
      name: "Color",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "length",
      name: "Length/ Diameter",
      customeWidth: 100,
      colSpan: 2,
      align: "center",
    },
    {
      id: "width",
      name: "Width",
      customeWidth: 100,
      colSpan: 2,
      align: "center",
    },
    {
      id: "customMarking",
      name: "Custom Marking Parameters",
      customeWidth: 100,
      colSpan: 2,
      align: "center",
    },
    {
      id: "thickness",
      name: "Thickness",
      customeWidth: 100,
      colSpan: 2,
      align: "center",
    },
    {
      id: "stdPkgQty",
      name: "Std. Pkg. Qty.",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "stdCtnQty",
      name: "Std. Ctn. Qty.",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
  ],
  [
    {
      id: "partNumber",
      name: " ",
      customeWidth: 100,
      colSpan: 1,
      align: "center",
    },
    {
      id: "material",
      name: " ",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "color",
      name: " ",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "lengthInch",
      name: "In.",
      customeWidth: 100,
      colSpan: 1,
      align: "center",
    },
    {
      id: "lengthMM",
      name: "mm",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "widthInch",
      name: "In.",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "widthMM",
      name: "mm",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "customMarking1",
      name: "Max. 16/3″ (4.77mm) Character Per Line",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "customMarking2",
      name: "Max. Lines of Text",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "thicknessInch",
      name: "In.",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "thicknessMM",
      name: "mm",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "stdPkgQty",
      name: " ",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
    {
      id: "stdCtnQty",
      name: " ",
      customeWidth: 50,
      colSpan: 1,
      align: "center",
    },
  ],
];
